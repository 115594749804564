import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Plyr from 'plyr';
import { getDocument, getWindow, ssrWindow } from 'ssr-window';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { VedioCountDTO } from 'src/app/Models/profile.model';
import { HomeService } from 'src/app/services/home/home.service';

import { DashjsPlyrDriver } from '../../dashjs-plyr-driver/dashjs-plyr-driver';

import { HlsjsPlyrDriver } from '../../hlsjs-plyr-driver/hlsjs-plyr-driver';
import { Title, Meta } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared/shared.service';
import { SessionService } from 'src/app/services/shared/session.service';
import { environment } from 'src/environments/environment';
import { io } from 'socket.io-client';
import { SocketService } from 'src/app/services/socket/socket.service';
import { VASTClient, VASTParser } from 'vast-client';
@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
})
export class ProgramsComponent implements OnInit, OnChanges, OnDestroy {
  mediadata: any[] = [];

  videoJsPlayerUrl: string;
  videoJsPlayerType: string;
  showAds = false;

  showAbout = true;
  showComment = false;
  players!: Plyr;
  plyrSetting: any;
  isFavourites = false;
  showAboutSection: boolean;
  isVideoPlayed = 0;
  orderObj: any;
  isReadMore = true;
  currentProgramData: any[] = [];
  nextProgramData: any[] = [];
  currentVedio: Plyr.Source[] = [{ src: '' }];
  currentVedioTitle: any;
  currentVedioDesc: any;
  itemPrice: any;
  isOneTimePurchase: any;
  isOneTimePurchasePaymentDone: boolean;
  nextVedioTitle: any;
  nextVedioImage: any;
  nxtVideoImg: any;
  nextVedioId: any;
  vedioID: any;
  paramsSub: any;
  isLoggedIn: any;
  vedioType: any;
  appBGColor: any;
  currentVedioIsPaid: any;
  loginRequired: any;
  accessType: any;
  primaryColor: any;
  playlistPosition = false;
  showLiveIndication = false;
  notLiveMsg = false;
  vedioCountDto: VedioCountDTO;
  videoPlayDuration = 0
  orgId: number;
  domain: any;
  testvedio =
    'https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8';
  videoSources: Plyr.Source[] = [
    {
      src: 'https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8',
      // provider: 'youtube',
    },
  ];
  options: Plyr.Options = {
    // autoplay: true,
    volume: 1,
    muted: false,
    invertTime: false,
    disableContextMenu: true,
    displayDuration: true,
    settings: ['quality', 'speed', 'loop'],
    controls: [
      'play-large',
      'play',
      'progress',
      'current-time',
      'mute',
      'volume',
      'settings',
      'fullscreen',
    ],
    captions: { active: true, update: true, language: 'en' },
    loop: { active: false },
  };

  //   options: Plyr.Options = {
  //   captions: { active: true, update: true, language: 'en' },
  // };

  poster: string;

  sources: Plyr.Source[] = [
    {
      type: 'video',
      src: 'https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8',
    },
  ];

  // dashjsDriver1 = new DashjsPlyrDriver(true);

  // dashjsDriver2 = new DashjsPlyrDriver(false);

  // hlsjsDriver1 = new HlsjsPlyrDriver(true);

  // hlsjsDriver2 = new HlsjsPlyrDriver(false);

  // hlsDriver = this.hlsjsDriver2
  loginUserDetail: any;
  paymentDone: any;
  PlanID: any;
  metaTags: any;
  shareImageon: string;
  vedioIsNull: boolean;
  realPlayTime: any;
  updateVedioDuration: any;
  vedioPlayDuration = 0;
  stopInterval: any;
  totalVedioDuration = 0;
  pauseTime: number;
  showNextVideoPlay = false;
  token: any;
  refreshToken: any;
  nextVideoImageOnVideo: any;
  playType: any;
  videoCountDecrease: any;
  countInterval: any;
  autoPlay = true;
  isVideoAvailable: any;
  userId: any;
  subscriptionPlanID: any;
  userPlanId: any;
  showafterLoad = false;
  autoPlayChange: boolean;
  isLiveStreamingVideo: boolean;
  currentVideoResolution: string;
  allResolutionsName: string[];
  allVideoResolutionList: any[];
  currentMediaItemValues: any;
  scrWidth: number;
  subTitle: string;
  isUserPlanPaymentDone: boolean;
  isSubscriptionAvailable: boolean;
  isDuration: boolean;
  isVastTag: boolean;
  inPlayerAds: boolean;
  vastTag: string;
  player: YT.Player;
  youtubeID: string;
  isYoutubeUrl = false
  socket: any;
  dataConsumedMB: number = 0;
  logoHeight: any
  smallScreen: boolean = false;
  itemType: any;
  videoJsPlayerUrl1: string;
  videoJsPlayerType1: string;
  nextPlayState: boolean;
  nextMediaItemId: any;
  isVideoPause: boolean = false;
  preRollUrl: any;
  preRollUrlType: any;
  midRollVastTagURL: string;
  vastTagEnabled: any = true;
  boundStorageEventListener: (event: StorageEvent) => void;
  trackingEvents: any;
  vastDTO: any;
  firstQuartileLogged: boolean = false;
  midLogged: boolean = false;
  thirdQuartileLogged: boolean = false;
  vastParser = new VASTParser();
  vastclient = new VASTClient();
  preRollData: any;
  midRollData: any;
  adsType: any;
  midRollDTO: any;
  ipAddress: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public homeService: HomeService,
    public messageService: MessageService,
    private elementRef: ElementRef,
    public sharedService: SharedService,
    private _Title: Title,
    private _Meta: Meta,
    private _sessionService: SessionService,
    private socketService: SocketService,
    // private VastParserService: VastParserService
  ) {
    this.scrWidth = window.innerWidth;
    this.smallScreen = window.innerWidth < 576;
    this.videoJsPlayerUrl = '';
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.vedioCountDto = new VedioCountDTO();
    // localStorage.removeItem('plyr');
    this.currentVedioIsPaid = false;
    this.videoJsPlayerType = '';
    this.poster = '';
    this.autoPlayChange = false;
    this.isLiveStreamingVideo = false;
    this.pauseTime = 0;
    this.isOneTimePurchasePaymentDone = false;
    this.currentVideoResolution = '720';
    this.allResolutionsName = ['720', '360', 'original', '1080'];
    this.allVideoResolutionList = [];
    this.subTitle = '';
    this.isUserPlanPaymentDone = false;
    this.isSubscriptionAvailable = false;
    this.isDuration = true;
    this.isVastTag = false;
    this.vastTag = '';
    this.midRollVastTagURL = '';
    this.inPlayerAds = false
    // this.boundStorageEventListener = this.vastCalled.bind(this);
  }

  ngOnInit(): void {
    console.log('ngOnInit programns');

    // setTimeout(()=> this.showAds = false , 50000);
    // let loginUserDetail = localStorage.getItem('loggedInUser')
    // if(loginUserDetail){
    //   this.vastTagEnabled = loginUserDetail
    //    ? JSON.parse(loginUserDetail).userDetails.vastTagEnabled
    //    : '';
    // }

    this.orderObj = this.route.snapshot.params.id;
    this.getLogoDimention()
    this.route.data.subscribe((response) => {
      this.currentMediaItemValues = response.data.data.currentMediaItem;
      this.nextMediaItemId = response.data.data.currentMediaItem.id
      this.itemType = response.data.data.currentMediaItem.itemType;
      this.allVideoResolutionList = response.data.data.currentMediaItem.videoDTO
        ? response.data.data.currentMediaItem.videoDTO.flavourList
        : [];
      this.videoJsPlayerUrl = response.data.data.currentMediaItem.videoDTO
        ? this.allVideoResolutionList.length && this.modifyVideoUrl()
          ? this.modifyVideoUrl()
          : response.data.data.currentMediaItem.videoDTO.path
        : response.data.data.currentMediaItem.videoUrl
          ? response.data.data.currentMediaItem.videoUrl
          : 'https://cdn.plyr.io/static/blank.mp4';
      //   if (response.data.data.nextMediaItem.wideArtworkId) {
      //     var width = 160;
      //     var height = 90;
      //     let id = response.data.data.nextMediaItem.wideArtworkId;
      //     response.data.nextMediaItem['newwideimg'] =
      //       `${appConfig.imageURL}` +
      //       id +
      //       '?height=' +
      //       height +
      //       '&width=' +
      //       width;
      //     response.data.data.nextMediaItem['nextVideoImg'] =
      //       `${appConfig.imageURL}` + id + '?height=180' + '&width=320';
      //   }
      // }
      // this.nextProgramData = response.data.nextMediaItem;
      // this.isLoggedIn = this._sessionService.getSession('loggedInUser');
      // if (this.isLoggedIn) {
      //   this.GetMediaItemWithLogin(this.orderObj);
      //   this.getRelatedMediaItemWith(1, 4, this.orderObj);
      // } else {
      //   this.GetMediaItemWithoutLogin(this.orderObj);
      //   this.getRelatedMediaItemWithout(1, 4, this.orderObj);
      // }
    });
    this.route.params.subscribe((params) => {
      this.orderObj = params.id;
      let loginData: any;
      loginData = this._sessionService.getSession('loggedInUser');
      this.loginUserDetail = loginData;
      this.isLoggedIn = loginData ? true : false;
      this.paymentDone = this.loginUserDetail
        ? JSON.parse(this.loginUserDetail).userDetails.isPaymentDone
        : '';
      this.userId = this.loginUserDetail
        ? JSON.parse(this.loginUserDetail).userDetails.id
        : null;
      this.subscriptionPlanID = this.loginUserDetail
        ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId
        : '';
      // this.PlanID = this.loginUserDetail ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId : '';
      this.token = this.loginUserDetail
        ? JSON.parse(this.loginUserDetail).token
        : '';
      this.domain =
        this.homeService.sendURL &&
        this.homeService.sendURL.replace('https://', '').replace('/', '');
      // this.router.navigate([], {
      //   relativeTo: this.route,
      //   queryParams: { domain: this.domain },
      //   queryParamsHandling: 'merge',
      // });
      // this.route.queryParams.subscribe((params) => {
      //   this.domain = params.domain;
      // });
      this.getPageCustomizationByDomain();
      this.getSubscribe();
      if (this.isLoggedIn) {
        this.userPlanDetails(this.userId);
        // this.getvedioPlayDuration(this.orderObj)
        // setTimeout(() => {
        setTimeout(() => {
          this.GetMediaItemWithLogin(this.orderObj);
          this.getRelatedMediaItemWith(1, 4, this.orderObj);
        }, 500);
        // }, 1000);
        // this.pageCustomization()
        this.refreshToken = setInterval(() => {
          this.loginUserDetail = '';
          this.loginUserDetail = localStorage.getItem('loggedInUser');
          this.token = this.loginUserDetail
            ? JSON.parse(this.loginUserDetail).token
            : '';
          this.getToken(this.token);
        }, 900000);
      } else {
        this.GetMediaItemWithoutLogin(this.orderObj);
        this.getRelatedMediaItemWithout(1, 4, this.orderObj);
        // this.pageCustomization()
      }
    });

    // this.getVastData();
    // window.addEventListener('storage',this.boundStorageEventListener );
    this.homeService.skipState$.subscribe((response: any) => {
      if (response) {
        // this.showAds=false;
        console.log('skip', response)
      }
    });

    let ipAdd: any = localStorage.getItem('ip');
    this.ipAddress = ipAdd ? JSON.parse(ipAdd).ip : null;
  }

  vastCalled(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      console.log('session changed:', event);
    }
    if (this.vastTag !== '' && this.isVastTag  && !this.inPlayerAds && this.vastTagEnabled && this.itemType !== 'LIVE_STREAMING' && this.itemType !== 'YOUTUBE') {
      this.getVastData();
    }
    else {
      // if(this.midRollVastTagURL !== '' && this.isVastTag && this.vastTagEnabled && this.itemType !== 'LIVE_STREAMING' && this.itemType !== 'YOUTUBE'){
      this.getMidRollVastData();
      // }
    }
    this.getLocalStorageVastDTO();
  }

  getPageCustomizationByDomain(): void {
    if (!this.domain) {
      return;
    }
    this.homeService
      .getPageCustomizationByDomain(this.domain)
      .subscribe((response: any) => {
        if (response.data) {
          this.orgId = response.data.organizationDTO.id
        }
      });
  }

  getVastData() {
    // this.videoJsPlayerUrl1 = 'https://servedbyadbutler.com/vast.spark?setID=52607&ID=187486'
    // this.videoJsPlayerType1= 'video/mp4' 
    if (this.vastTag !== '' && this.isVastTag && !this.inPlayerAds && this.vastTagEnabled && this.itemType !== 'LIVE_STREAMING' && this.itemType !== 'YOUTUBE') {
      let vastData = localStorage.getItem('vastUrl') ? localStorage.getItem('vastUrl') : '';
      if (vastData) {
        console.log('working vast data', JSON.parse(vastData));
        let data = JSON.parse(vastData)[0];
        this.preRollUrl = data.fileURL ? data.fileURL : '';
        this.preRollUrlType = data.mimeType ? data.mimeType : '';
        this.videoJsPlayerUrl1 = this.preRollUrl
        this.videoJsPlayerType1 = this.preRollUrlType
      }
      else {
        this.showAds = false;
      }
    }
    else {
      this.showAds = false;
      localStorage.removeItem('vastUrl');
    }
  }

  sendDataUsageToServer(event: any) {
    const sendData = {
      userId: this.userId ? this.userId : 0,
      mediaId: this.currentMediaItemValues ? this.currentMediaItemValues.id : 0,
      orgId: this.orgId ? this.orgId : 0,
      usage: event.byteLength ? event.byteLength : 0,
      device: "WEB",
      mediaType: this.isLiveStreamingVideo === true ? 'LIVESTREAM' : 'VOD',
      duration: this.videoPlayDuration ? this.videoPlayDuration : 0,
    }
    this.socketService.emit('analytics', sendData);
  }

  sendDataToServer(type: any) {
    const data = {
      type: type,
      userId: this.userId ? this.userId : 0,
      mediaId: this.currentMediaItemValues ? this.currentMediaItemValues.id : 0,
      orgId: this.orgId ? this.orgId : 0,
      ip: this.ipAddress,
      device: "WEB",
      deviceId: 0,
      mediaType: this.isLiveStreamingVideo === true ? 'LIVESTREAM' : 'VOD',
    };
    this.socketService.emit('viewer-action', data);
    if (type === 'REMOVE') {
      const sendData = {
        userId: this.userId ? this.userId : 0,
        mediaId: this.currentMediaItemValues ? this.currentMediaItemValues.id : 0,
        orgId: this.orgId ? this.orgId : 0,
        usage: 0,
        device: "WEB",
        mediaType: this.isLiveStreamingVideo === true ? 'LIVESTREAM' : 'VOD',
        duration: this.videoPlayDuration ? this.videoPlayDuration : 0,
      }
      this.socketService.emit('analytics', sendData);
    }
  }

  modifyVideoUrl(): string {
    if (this.allVideoResolutionList.length) {
      const flavourList = this.allVideoResolutionList.filter(
        (value: any) => value.size === this.currentVideoResolution && value.src
      );
      return flavourList.length ? flavourList[0].src : '';
    }
    return '';
  }
  resolutionChange(event: any) {
    if (event) {
      this.currentVideoResolution = '360';
      this.videoJsPlayerUrl = this.modifyVideoUrl()
        ? this.modifyVideoUrl()
        : this.currentMediaItemValues.videoDTO
          ? this.currentMediaItemValues.videoDTO.path
          : this.currentMediaItemValues.videoUrl
            ? this.currentMediaItemValues.videoUrl
            : 'https://cdn.plyr.io/static/blank.mp4';
      this.currentVideoResolution = '720';
    }
  }
  ngOnChanges(): void {
    console.log('ngONChnages programns');
    this.showAds = false;
    const loginData = this._sessionService.getSession('loggedInUser');
    this.loginUserDetail = loginData;
    this.isLoggedIn = loginData ? true : false;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    this.sendDataToServer('REMOVE');
  }

  ngOnDestroy() {
    let loginData: any;
    loginData = localStorage.getItem('loggedInUser');
    this.isLoggedIn = loginData ? true : false;
    this.currentVideoResolution = '720';
    setTimeout(() => {
      this.sendDataToServer('REMOVE');
    }, 1000);
    if (this.isLoggedIn) {
      clearInterval(this.refreshToken);
      this.totalVedioDuration = this.totalVedioDuration
        ? this.totalVedioDuration
        : 0;
      this.realPlayTime = this.realPlayTime ? this.realPlayTime : 0;
      if (
        this.totalVedioDuration ||
        (this.totalVedioDuration === 0 && this.realPlayTime)
      ) {
        if (
          this.totalVedioDuration.toFixed(0) !== this.realPlayTime.toFixed(0)
        ) {
          this.updateVideoPause();
        }
        clearInterval(this.stopInterval);
        clearInterval(this.updateVedioDuration);
      }
    } else {
      clearInterval(this.stopInterval);
      clearInterval(this.updateVedioDuration);
    }
  }

  languageChanged(driver: HlsjsPlyrDriver, plyr: Plyr) {
    setTimeout(() => (driver.hls.subtitleTrack = plyr.currentTrack), 50);
  }

  // played() {
  //   this.hlsjsDriver2.load(this.sources[0].src);
  // }

  // played() {
  //   this.dashjsDriver2.load('https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8');
  // }
  //   ngOnDestroy() {
  //     this.paramsSub.unsubscribe();
  // }

  getToken(token: any) {
    this.sharedService.updateLoader(false);
    this.homeService.getToken(token).subscribe(
      (response: any) => {
        this.loginUserDetail = localStorage.getItem('loggedInUser')
          ? JSON.parse(this.loginUserDetail)
          : '';
        this.loginUserDetail.token = response.data;
        localStorage.setItem(
          'loggedInUser',
          JSON.stringify(this.loginUserDetail)
        );
        this.token = '';
      },
      (error) => {
        this.sharedService.updateLoader(false);
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
      }
    );
    this.sharedService.updateLoader(false);
  }

  userPlanDetails(id: any) {
    this.sharedService.updateLoader(false);
    this.homeService.userPlanDetails(id).subscribe(
      (response: any) => {
        this.userPlanId = response.data.id;
        this.isUserPlanPaymentDone = response.data.isPaymentDone;
      },
      (error) => {
        this.sharedService.updateLoader(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
    this.sharedService.updateLoader(false);
  }

  completeVideo(event: Plyr.PlyrEvent) {
    this.isVideoPlayed = 0;
  }

  playedFullScreen(event: Plyr.PlyrEvent) { }

  stateChange(event: Plyr.PlyrEvent) { }

  playVideo(): void {
    this.players.play();
  }

  played(event: any) {
    // console.log(this.hlsDriver.updateSource);
    // if (this.vedioType) {
    //   // this.hlsDriver.load(this.currentVedio[0].src);
    //   this.vedioPlayCount();
    // }
    this.vedioPlayCount();
    setTimeout(() => {
      this.sendDataToServer('ADD');
    }, 1000);
  }

  playedAds(event: any) {
    let eventData;
    let trackingEvents;
    if (this.adsType === 'preroll') {
      eventData = this.preRollData;
      trackingEvents = this.preRollData?.creatives[0]?.trackingEvents;
    }
    else if (this.adsType === 'midroll') {
      eventData = this.midRollData;
      trackingEvents = this.midRollData?.creatives[0]?.trackingEvents;
    }
    let impressionUrl = eventData ? (eventData?.impressionURLTemplates ? eventData.impressionURLTemplates[0] : '') : '';
    let creativeView = eventData ? (trackingEvents?.creativeView ? trackingEvents.creativeView[0] : '') : '';
    let startUrl = eventData ? (trackingEvents?.start ? trackingEvents.start[0] : '') : '';
    if (impressionUrl) {
      this.getTrackingEventAPI(impressionUrl);
    }
    if (creativeView) {
      this.getTrackingEventAPI(creativeView);
    }
    if (startUrl) {
      this.getTrackingEventAPI(startUrl);
    }
  }

  paused(event: any) {
    setTimeout(() => {
      this.sendDataToServer('REMOVE');
    }, 1000);
    this.updateVideoPause(event)
  }

  vedioPlayCount() {
    this.sharedService.updateLoader(false);
    this.isVideoPlayed = this.isVideoPlayed + 1;
    if (this.isVideoPlayed === 1 && parseInt(this.orderObj)) {
      const data = JSON.parse(JSON.stringify(this.vedioCountDto));

      data.deviceType = 'WEBSITE';
      data.mediaId = this.vedioID;
      data.mediaItemId = parseInt(this.orderObj);
      data.mediaType = 'VIDEO';

      if (this.isLoggedIn) {
        this.homeService.postMediaPlayCount(data).subscribe((response: any) => {
          this.sharedService.updateLoader(false);
        });
      } else {
        this.homeService
          .postMediaPlayCountWithouAuth(data)
          .subscribe((response: any) => {
            this.sharedService.updateLoader(false);
          });
      }
    }
  }

  play(): void {
    // this.players.play(); // or this.plyr.player.play()
    //console.log(this.players.source);

    if (this.isLoggedIn) {
      this.updateVedioDuration = setInterval(() => {
        this.totalVedioDuration = this.totalVedioDuration
          ? this.totalVedioDuration
          : 0;
        this.realPlayTime = this.realPlayTime ? this.realPlayTime : 0;
        if (
          this.totalVedioDuration ||
          (this.totalVedioDuration === 0 && this.realPlayTime)
        ) {
          if (
            this.totalVedioDuration.toFixed(0) !== this.realPlayTime.toFixed(0)
          ) {
            this.updateVedioPlay();
          }

          // clearInterval(this.stopInterval);
        }
      }, 5000);
    }
  }

  showabout() {
    this.showAbout = true;
    this.showComment = false;
  }

  updateVideoPause(event?: any) {
    if (
      this.isLoggedIn &&
      !this.isLiveStreamingVideo &&
      !(
        this.vedioIsNull ||
        this.currentVedioIsPaid ||
        this.isOneTimePurchase ||
        this.loginRequired
      ) &&
      event &&
      Math.round(event.timeStamp / 1000) > 0
    ) {
      this.sharedService.updateLoader(false);
      const dataToSend = {
        mediaItemId: this.orderObj,
        pauseTime: event ? Math.round(event.timeStamp / 1000) : 0,
      };
      this.homeService.updateVideoPause(dataToSend).subscribe(
        (response: any) => {
          this.sharedService.updateLoader(false);
        },
        (error: any) => {
          this.sharedService.updateLoader(false);
          // this.messageService.add({
          //   severity: 'error',
          //   summary: 'Error',
          //   detail: error.error.message,
          // });
        }
      );
      this.sharedService.updateLoader(false);
    }
  }

  getMidRollVastData(): void {
    if (this.midRollVastTagURL !== '' && this.isVastTag && !this.inPlayerAds && this.vastTagEnabled && this.itemType !== 'LIVE_STREAMING' && this.itemType !== 'YOUTUBE') {
      let vastData = localStorage.getItem('vastMidrollUrl') ? localStorage.getItem('vastMidrollUrl') : '';
      if (vastData) {
        console.log('working vast data', JSON.parse(vastData));
        let data = JSON.parse(vastData)[0];
        this.videoJsPlayerUrl1 = data.fileURL ? data.fileURL : '';
        this.videoJsPlayerType1 = data.mimeType ? data.mimeType : '';
        // localStorage.removeItem('vastMidrollUrl');
      }
      else {
        this.showAds = false;
      }
    }
    else {
      this.showAds = false;
      localStorage.removeItem('vastMidrollUrl');
    }
  }

  updateVedioPlay(event?: any) {
    // console.log('updateVedioPlay',event)
    const pauseTime = event ? event.target.currentTime : 0
    this.videoPlayDuration = pauseTime
    const videoDuration = event ? event.target.duration - 1 : 0
    let currVideoDuration = this.currentMediaItemValues.videoDTO ? this.currentMediaItemValues.videoDTO.duration : videoDuration;
    if (pauseTime.toFixed(0) === currVideoDuration.toFixed(0) && currVideoDuration > 0 && pauseTime > 0) {
      if (this.autoPlay) {
        this.nextVedio(this.nextVedioId, 'next')
      }
    }

    if (Math.round(pauseTime.toFixed(0)) === Math.round(currVideoDuration.toFixed(0) / 2) && currVideoDuration > 0 && pauseTime > 0 && !this.inPlayerAds) {
      if (this.midRollDTO) {
        console.log("upper limit %%%%")
        // this.isVideoPause = true;
        this.pauseTime = Math.round(currVideoDuration.toFixed(0) / 2) + 1;
        this.midRollData = this.midRollDTO;
        this.adsType = 'midroll'
        this.videoJsPlayerUrl1 = this.midRollData?.creatives[0]?.mediaFiles[0]?.fileURL;
        this.videoJsPlayerType1 = this.midRollData?.creatives[0]?.mediaFiles[0]?.mimeType;
        this.showAds = true
      }
      console.log('puase time ', currVideoDuration)
      //  this.pauseTime = Math.round(pauseTime.toFixed(0))
      console.log(this.pauseTime)
    }
    this.sharedService.updateLoader(false);
  }
  updateAdsVedioPlay(event?: any) {
    this.isVideoPause = true;
    const pauseTime = event ? event.target.currentTime : 0
    // console.log('yea player', pauseTime)
    let playDuration = event ? event.target.currentTime : 0
    this.videoPlayDuration = pauseTime
    const videoDuration = event ? event.target.duration - 1 : 0
    // let currVideoDuration = this.currentMediaItemValues.videoDTO.duration;
    let firstQuartile = videoDuration / 4;
    let mid = videoDuration / 2;
    let thirdQuartile = videoDuration * 3 / 4;
    let eventData;
    let trackingEvents;
    if (this.adsType === 'preroll') {
      eventData = this.preRollData;
      trackingEvents = this.preRollData?.creatives[0]?.trackingEvents;
    }
    else if (this.adsType === 'midroll') {
      eventData = this.midRollData;
      trackingEvents = this.midRollData?.creatives[0]?.trackingEvents;
    }
    if (playDuration > thirdQuartile && !this.thirdQuartileLogged) {
      this.thirdQuartileLogged = true;
      const url = eventData ? (trackingEvents?.thirdQuartile ? trackingEvents.thirdQuartile[0] : '') : '';
      if (url) {
        this.getTrackingEventAPI(url);
      }
    }
    else if (playDuration > mid && !this.midLogged) {
      this.midLogged = true;
      const url = eventData ? (trackingEvents?.midpoint ? trackingEvents.midpoint[0] : '') : '';
      if (url) {
        this.getTrackingEventAPI(url);
      }
    }
    else if (playDuration > firstQuartile && !this.firstQuartileLogged) {
      this.firstQuartileLogged = true;
      const url = eventData ? (trackingEvents?.firstQuartile ? trackingEvents.firstQuartile[0] : '') : '';
      if (url) {
        this.getTrackingEventAPI(url);
      }
    }

    if (pauseTime.toFixed(0) === videoDuration.toFixed(0) && videoDuration > 0 && pauseTime > 0) {
      this.showAds = false
      this.isVideoPause = false;
      const url = eventData ? (trackingEvents?.complete ? trackingEvents.complete[0] : '') : '';
      if (url) {
        this.getTrackingEventAPI(url);
      }
    }
    this.sharedService.updateLoader(false);
  }


  updateAdsVedioPlayOnError(event?: any) {
    this.isVideoPause = true;
    this.showAds = false
    this.isVideoPause = false;
    this.sharedService.updateLoader(false);
  }

  calculateDataConsumption(duration: any) {
    const videoDuration = duration;

    // Check if Network Information API is supported
    if ('connection' in navigator) {
      const connection = (navigator as any).connection;
      if (connection.saveData) {
        // User is in a data-saving mode, handle accordingly or use default data consumption rate
        return;
      }

      const dataRate = connection.downlink; // Network speed in Megabits per second
      // console.log(dataRate,'dataRate')
      const dataConsumed = (dataRate * videoDuration) / 8; // Calculate data consumed in Megabits
      // console.log(dataConsumed,'dataConsumed')
      this.dataConsumedMB = dataConsumed / 1024; // Convert to Megabytes
      // console.log('Data consumed:', this.dataConsumedMB, 'MB');
    }
  }

  showcomment() {
    this.showAbout = false;
    this.showComment = true;
  }

  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          this.appBGColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        // playlistPosition = true -> sidebar view || false -> below Veiw
        this.playlistPosition =
          response.data.pageCustomizationDTO.playlistPosition;
        this.isDuration = response.data.webAppSettingDTO.showProgramDuration;
      }
    });
  }

  getvedioPlayDuration(id: any) {
    this.sharedService.updateLoader(false);
    this.homeService.getvedioPlayDuration(id).subscribe(
      (response: any) => {
        this.vedioPlayDuration = response.data.pauseTime
          ? response.data.pauseTime
          : 0;
        this.totalVedioDuration = response.data.duration
          ? response.data.duration
          : 0;
        this.sharedService.updateLoader(false);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  // deletevedioPlayDuration(id:any) {
  //   this.homeService.deletevedioPlayDuration(id).subscribe((response: any) => {
  //     this.vedioPlayDuration = response.data.pauseTime;
  //     this.totalVedioDuration = response.data.duration
  //   }, (error) => {
  //     this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
  //   });
  // }

  GetMediaItemWithLogin(id: any) {
    this.homeService.getMediaDataByIdWith(id).subscribe(
      (response: any) => {
        this.loginUserDetail = localStorage.getItem('loggedInUser');
        if (response.data.nextMediaItem) {
          if (response.data.nextMediaItem.wideArtworkId) {
            var width = 160;
            var height = 90;
            let id = response.data.nextMediaItem.wideArtworkId;
            response.data.nextMediaItem['newwideimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
            response.data.nextMediaItem['nextVideoImg'] =
              `${appConfig.imageURL}` + id + '?height=180' + '&width=320';
          }
        }
        if (this.scrWidth < 500) {
          this.poster =
            response.data.currentMediaItem.wideArtworkId &&
            `${appConfig.imageURL}${response.data.currentMediaItem.wideArtworkId}?height=360&width=640`;
        } else {
          this.poster =
            response.data.currentMediaItem.wideArtworkId &&
            `${appConfig.imageURL}${response.data.currentMediaItem.wideArtworkId}?height=585&width=1040`;
        }
        this.subTitle =
          response.data.currentMediaItem &&
          response.data.currentMediaItem.subTitle;
        this.isLiveStreamingVideo =
          response.data.currentMediaItem.itemType === 'LIVE_STREAMING'
            ? true
            : false;
        this.currentProgramData = response.data.currentMediaItem;
        this.pauseTime = response.data.currentMediaItem.pauseTime;
        if (this.pauseTime) {
          this.getvedioPlayDuration(this.orderObj);
        }
        this.nextProgramData = response.data.nextMediaItem;

        this.nextVideoImageOnVideo = response.data.nextVideoImg;
        this.vedioID = response.data.currentMediaItem.videoId;
        this.PlanID = this.loginUserDetail
          ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId
          : '';
        this.currentVedioIsPaid = true;
        this.itemPrice = response.data.currentMediaItem.price;
        this.isOneTimePurchase =
          response.data.currentMediaItem.isOneTimePurchase;
        this.isOneTimePurchasePaymentDone =
          response.data.currentMediaItem.isOneTimePurchasePaymentDone;
        console.log(this.isOneTimePurchasePaymentDone);
        if (this.isLoggedIn) {
          this.autoPlay = response.data.currentMediaItem.isAutoPlayStatus;
          this.autoPlayChange = response.data.currentMediaItem.isAutoPlayStatus;
        } else {
          this.autoPlay =
            this._sessionService.getSession('autoplay') === 'true'
              ? true
              : false;
          this.autoPlayChange =
            this._sessionService.getSession('autoplay') === 'true'
              ? true
              : false;
        }
        if (response.data.currentMediaItem.mediaAccessType === 'PAID') {
          this.isSubscriptionAvailable = response.data.currentMediaItem
            .subscriptionPlanIds.length
            ? true
            : false;
          response.data.currentMediaItem.subscriptionPlanIds.forEach(
            (element: any) => {
              if (element == this.PlanID && this.paymentDone) {
                this.currentVedioIsPaid = false;
              }
              if (element == this.userPlanId && this.isUserPlanPaymentDone) {
                this.currentVedioIsPaid = false;
              }
            }
          );
        } else {
          this.currentVedioIsPaid = false;
        }
        // this.currentVedioIsPaid = response.data.currentMediaItem.isPaid ? response.data.currentMediaItem.isPaid : false;

        // if(this.currentVedioIsPaid === true){
        //   // this.options.autoplay = false
        //   this.options.autoplay = false
        // }else{
        // this.currentVedioIsPaid = false
        this.loginUserDetail = localStorage.getItem('loggedInUser');
        this.paymentDone = this.loginUserDetail
          ? JSON.parse(this.loginUserDetail).userDetails.isPaymentDone
          : '';
        if (
          !this.paymentDone &&
          response.data.currentMediaItem.mediaAccessType === 'PAID'
        ) {
          this.currentVedioIsPaid = true;
        }
        if (
          response.data.currentMediaItem.isOneTimePurchasePaymentDone === true
        ) {
          this.vedioIsNull = false;
          this.currentVedioIsPaid = false;
        }
        if (this.currentVedioIsPaid) {
          // if(this.paymentDone === false){
          this.options.autoplay = false;
          this.currentVedioIsPaid = true;
          // }
          // else{
          //   // this.currentVedioIsPaid = false
          //   this.players.autoplay = true // or this.plyr.player.play()
          //   this.options.autoplay = true
          //   setTimeout(() => {
          //     this.play()
          //   }, 1000);
          // }
        } else if (!this.currentVedioIsPaid) {
          this.currentVedioIsPaid = false;
          //        this.players.autoplay = true // or this.plyr.player.play()
          //      this.options.autoplay = true
        }
        // }
        this.isVideoAvailable = response.data.currentMediaItem.isVideoAvailable;
        // let vedioSrc = response.data.currentMediaItem.videoDTO
        //   ? response.data.currentMediaItem.videoDTO.path
        //   : response.data.currentMediaItem.videoUrl
        //   ? response.data.currentMediaItem.videoUrl
        //   : 'https://cdn.plyr.io/static/blank.mp4';
        this.currentMediaItemValues = response.data.currentMediaItem;
        this.allVideoResolutionList = response.data.currentMediaItem.videoDTO
          ? response.data.currentMediaItem.videoDTO.flavourList
          : [];
        let vedioSrc = response.data.currentMediaItem.videoDTO
          ? this.allVideoResolutionList.length && this.modifyVideoUrl()
            ? this.modifyVideoUrl()
            : response.data.currentMediaItem.videoDTO.path
          : response.data.currentMediaItem.videoUrl
            ? response.data.currentMediaItem.videoUrl
            : 'https://cdn.plyr.io/static/blank.mp4';
        this.isYoutubeUrl = response.data.currentMediaItem.isYoutubeUrl
        if (this.isYoutubeUrl) {
          let charCount = vedioSrc.split('').filter((x: any) => x == '?').length;
          if (charCount == 2) {
            let index = vedioSrc.lastIndexOf('?')
            vedioSrc = vedioSrc.slice(0, index)
          }
          let youtubeID = this.getYouTubeVideoId(vedioSrc)
          this.youtubeID = youtubeID ? youtubeID : ''
        }
        if (!this.isVideoAvailable) {
          this.vedioIsNull = true;
        } else {
          this.vedioIsNull = false;
        }
        if (response.data.currentMediaItem.liveStreamDataDTO !== null) {
          if (
            response.data.currentMediaItem.liveStreamDataDTO.streamStatus ===
            'LIVE'
          ) {
            vedioSrc = response.data.currentMediaItem.liveStreamDataDTO.m3u8Url;
            this.showLiveIndication = true;
            this.notLiveMsg = false;
            this.vedioIsNull = false;
          } else if (
            response.data.currentMediaItem.liveStreamDataDTO.streamStatus ===
            'NOTLIVE' &&
            !response.data.currentMediaItem.videoUrl
          ) {
            this.showLiveIndication = false;
            this.notLiveMsg = true;
            this.vedioIsNull = false;
          }
        } else {
          this.showLiveIndication = false;
        }

        var url = vedioSrc;
        this.videoJsPlayerUrl = vedioSrc;
        var urlExt = url ? url.substr(url.lastIndexOf('.') + 1) : null;
        if (urlExt === 'm3u8') {
          this.vedioType = true;
          this.currentVedio = [];
          let newObj = {
            src: '',
          };
          let newarray: any[] = [];
          this.currentVedio.push(newObj);
          // this.currentVedio[0].src = vedioSrc
        } else {
          this.vedioType = false;
          this.currentVedio = vedioSrc;
        }
        this.isFavourites = response.data.currentMediaItem.isFavourites
          ? response.data.currentMediaItem.isFavourites
          : false;
        this.currentVedioTitle = response.data.currentMediaItem.title;
        this.currentVedioDesc = response.data.currentMediaItem.description
          ? response.data.currentMediaItem.description.trim()
          : '';
        this.showAboutSection = this.currentVedioDesc.length > 0 ? true : false;
        this.nextVedioId =
          response.data.nextMediaItem && response.data.nextMediaItem.id;
        this.nextVedioTitle =
          response.data.nextMediaItem && response.data.nextMediaItem.title;
        this.nextVedioImage =
          response.data.nextMediaItem && response.data.nextMediaItem.newwideimg;
        this.nxtVideoImg =
          response.data.nextMediaItem &&
          response.data.nextMediaItem.nextVideoImg;
        // this.showPage=true;
        setTimeout(() => {
          this.showafterLoad = true;
        }, 800);

        // added the validation to check whether the payment has beed done or not.
        if (response.data.currentMediaItem.mediaAccessType === 'FREE') {
          this.isOneTimePurchase = false;
        }
        if (
          response.data.currentMediaItem.mediaAccessType === 'PAID' &&
          response.data.currentMediaItem.isOneTimePurchasePaymentDone
        ) {
          this.loginRequired = false;
          this.isOneTimePurchase = false;
          this.currentVedioIsPaid = false;
          this.vedioIsNull = false;
        }
        if (
          this.loginUserDetail &&
          JSON.parse(this.loginUserDetail).userDetails &&
          response.data.currentMediaItem.subscriptionPlanIds.some(
            (value: any) =>
              value ===
              JSON.parse(this.loginUserDetail).userDetails
                .userSubscriptionPlanId && this.paymentDone
          )
        ) {
          this.loginRequired = false;
          this.isOneTimePurchase = false;
          this.currentVedioIsPaid = false;
          this.vedioIsNull = false;
        }
        if (this.isLiveStreamingVideo) {
          this.getBroadCastingStatus(this.orderObj);
        }

        console.log("vast parsed", response.data.currentMediaItem.vastTagDetails)

        this.isReadMore = true;
        // this.inPlayerAds = response.data.currentMediaItem.vastTagDetails
        //   ?
        //   response.data.currentMediaItem.vastTagDetails.enableVastTag
        //     ?
        //     (response.data.currentMediaItem.vastTagDetails.adExchangeUrl && response.data.currentMediaItem.vastTagDetails.midRangeExchangeUrl)
        //       ?
        //       false
        //       :
        //       true
        //     :
        //     false
        //   :
        //   false;
        this.isVastTag = response.data.currentMediaItem.vastTagDetails ? response.data.currentMediaItem.vastTagDetails.enableVastTag : false;
        this.vastTag =
          response.data.currentMediaItem.vastTagDetails ?
            response.data.currentMediaItem.vastTagDetails.enablePublisherUrl ?
              response.data.currentMediaItem.vastTagDetails.publisherUrl :
              response.data.currentMediaItem.vastTagDetails.adExchangeUrl :
            '';
        // this.vastTag = response.data.currentMediaItem.vastTagDetails.enablePublisherUrl ? response.data.currentMediaItem.vastTagDetails.publisherUrl :
        //   response.data.currentMediaItem.vastTagDetails ? response.data.currentMediaItem.vastTagDetails.adExchangeUrl : '';
        this.midRollVastTagURL = response.data.currentMediaItem.vastTagDetails ? response.data.currentMediaItem.vastTagDetails.midRangeExchangeUrl : '';
        let itemTypeValue = response.data.currentMediaItem.itemType ? response.data.currentMediaItem.itemType : '';
        if(this.vastTag !== '' && this.isVastTag && response.data.currentMediaItem.vastTagDetails.enablePublisherUrl && !(this.currentVedioIsPaid || this.isOneTimePurchase || this.loginRequired || itemTypeValue === 'LIVE_STREAMING') || this.vedioIsNull){
          this.inPlayerAds = response.data.currentMediaItem.vastTagDetails.enablePublisherUrl 
        }else{
          this.inPlayerAds = false
        }
        if (this.vastTag !== '' && this.isVastTag && !this.inPlayerAds && !(this.currentVedioIsPaid || this.isOneTimePurchase || this.loginRequired || itemTypeValue === 'LIVE_STREAMING') || this.vedioIsNull) {
          this.vastParser.getAndParseVAST(this.vastTag)
            .then(res => {
              console.log("vast parsed", this.inPlayerAds)
              this.preRollData = res.ads[0];
              this.adsType = 'preroll';
              this.videoJsPlayerUrl1 = this.preRollData?.creatives[0]?.mediaFiles[0]?.fileURL;
              this.videoJsPlayerType1 = this.preRollData?.creatives[0]?.mediaFiles[0]?.mimeType;
              this.showAds = !this.inPlayerAds;
            })
            .catch(err => {
              console.log('err', err);
            });
        }
        if (this.midRollVastTagURL && this.midRollVastTagURL !== '' && this.isVastTag && !this.inPlayerAds && itemTypeValue !== 'LIVE_STREAMING') {
          this.vastParser.getAndParseVAST(this.midRollVastTagURL)
            .then(res => {
              this.midRollDTO = res.ads[0];
            })
            .catch(err => {
              console.log('err', err);
            });
        }
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  addPlay() {
    this.videoJsPlayerUrl1 = 'https://d22trojezeokn1.cloudfront.net/1203/public/3201/trim/1681226175393_15sec_ADV2_GLITCH_HORIZONTAL_4633274093057044106.m3u8'
    this.videoJsPlayerType1 = 'application/x-mpegURL'
  }

  getplayerdata() {
    //    console.log(this.players.elements);
  }

  GetMediaItemWithoutLogin(id: any) {
    this.homeService.getMediaDataByIdWithout(id).subscribe(
      (response: any) => {
        if (response.data.nextMediaItem) {
          if (response.data.nextMediaItem.wideArtworkId) {
            var width = 160;
            var height = 90;
            let id = response.data.nextMediaItem.wideArtworkId;
            response.data.nextMediaItem['newwideimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
            response.data.nextMediaItem['newwideimgshare'] =
              `${appConfig.imageURL}` + id + '?height=480' + '&width=270';
            response.data.nextMediaItem['nextVideoImg'] =
              `${appConfig.imageURL}` + id + '?height=180' + '&width=320';
          }
        }
        if (this.scrWidth < 500) {
          this.poster =
            response.data.currentMediaItem.wideArtworkId &&
            `${appConfig.imageURL}${response.data.currentMediaItem.wideArtworkId}?height=360&width=640`;
        } else {
          this.poster =
            response.data.currentMediaItem.wideArtworkId &&
            `${appConfig.imageURL}${response.data.currentMediaItem.wideArtworkId}?height=585&width=1040`;
        }
        this.isLiveStreamingVideo =
          response.data.currentMediaItem.itemType === 'LIVE_STREAMING'
            ? true
            : false;
        this.subTitle =
          response.data.currentMediaItem &&
          response.data.currentMediaItem.subTitle;
        this.currentProgramData = response.data.currentMediaItem;
        this.nextProgramData = response.data.nextMediaItem;
        this.vedioID = response.data.currentMediaItem.videoId;
        if (this.isLoggedIn) {
          this.autoPlay = response.data.currentMediaItem.isAutoPlayStatus;
          this.autoPlayChange = response.data.currentMediaItem.isAutoPlayStatus;
        } else {
          this.autoPlay =
            this._sessionService.getSession('autoplay') === 'true'
              ? true
              : false;
          this.autoPlayChange =
            this._sessionService.getSession('autoplay') === 'true'
              ? true
              : false;
        }
        this.totalVedioDuration = response.data.currentMediaItem.videoDTO
          ? response.data.currentMediaItem.videoDTO.duration
          : response.data.currentMediaItem.videoDuration
            ? response.data.currentMediaItem.videoDuration
            : 0;
        this.currentVedioIsPaid =
          response.data.currentMediaItem.mediaAccessType === 'PAID'
            ? true
            : false;
        this.accessType =
          response.data.currentMediaItem.mediaAccessType === 'ACCESSREQUIRED'
            ? true
            : false;
            // console.log(this.accessType , "accesType")
        this.isSubscriptionAvailable = response.data.currentMediaItem
          .subscriptionPlanIds.length
          ? true
          : false;
        if (this.accessType && !this.isLoggedIn) {
          // console.log("Hekko",this.accessType,this.isLoggedIn)
          this.loginRequired = true;
        } else {
          // console.log("Hekko 2",this.accessType,this.isLoggedIn)
 
          this.loginRequired = false;
        }
        this.isVideoAvailable = response.data.currentMediaItem.isVideoAvailable;
        // let vedioSrc = response.data.currentMediaItem.videoDTO
        //   ? response.data.currentMediaItem.videoDTO.path
        //   : response.data.currentMediaItem.videoUrl
        //   ? response.data.currentMediaItem.videoUrl
        //   : 'https://cdn.plyr.io/static/blank.mp4';
        this.currentMediaItemValues = response.data.currentMediaItem;
        this.allVideoResolutionList = response.data.currentMediaItem.videoDTO
          ? response.data.currentMediaItem.videoDTO.flavourList
          : [];
        let vedioSrc = response.data.currentMediaItem.videoDTO
          ? this.allVideoResolutionList.length && this.modifyVideoUrl()
            ? this.modifyVideoUrl()
            : response.data.currentMediaItem.videoDTO.path
          : response.data.currentMediaItem.videoUrl
            ? response.data.currentMediaItem.videoUrl
            : 'https://cdn.plyr.io/static/blank.mp4';
        this.isYoutubeUrl = response.data.currentMediaItem.isYoutubeUrl
        if (this.isYoutubeUrl) {
          let charCount = vedioSrc.split('').filter((x: any) => x == '?').length;
          if (charCount == 2) {
            let index = vedioSrc.lastIndexOf('?')
            vedioSrc = vedioSrc.slice(0, index)
          }
          let youtubeID = this.getYouTubeVideoId(vedioSrc)
          this.youtubeID = youtubeID ? youtubeID : ''
        }
        if (!this.isVideoAvailable) {
          this.vedioIsNull = true;
        } else {
          this.vedioIsNull = false;
        }
        if (response.data.currentMediaItem.liveStreamDataDTO !== null) {
          if (
            response.data.currentMediaItem.liveStreamDataDTO.streamStatus ===
            'LIVE'
          ) {
            vedioSrc = response.data.currentMediaItem.liveStreamDataDTO.m3u8Url;
            this.showLiveIndication = true;
            this.notLiveMsg = false;
            this.vedioIsNull = false;
          } else if (
            response.data.currentMediaItem.liveStreamDataDTO.streamStatus ===
            'NOTLIVE' &&
            !response.data.currentMediaItem.videoUrl
          ) {
            this.showLiveIndication = false;
            this.notLiveMsg = true;
            this.vedioIsNull = false;
          }
        } else {
          this.showLiveIndication = false;
        }
        var url = vedioSrc;
        this.videoJsPlayerUrl = vedioSrc;
        var urlExt = url ? url.substr(url.lastIndexOf('.') + 1) : null;

        if (urlExt === 'm3u8') {
          this.videoJsPlayerType = 'application/x-mpegURL';
          this.vedioType = true;
          //  this.currentVedio[0].src = vedioSrc
        } else {
          this.vedioType = false;
          this.currentVedio = vedioSrc;
          this.videoJsPlayerType = `video/${urlExt}`;
        }
        this.currentVedioTitle = response.data.currentMediaItem.title;
        this.currentVedioDesc = response.data.currentMediaItem.description
          ? response.data.currentMediaItem.description.trim()
          : '';
        this.itemPrice = response.data.currentMediaItem.price;
        this.isOneTimePurchase =
          response.data.currentMediaItem.isOneTimePurchase;
        if (this.currentVedioDesc) {
          this.showAboutSection =
            this.currentVedioDesc.length > 0 ? true : false;
        } else {
          this.showAboutSection = false;
        }
        this.nextVedioId =
          response.data.nextMediaItem && response.data.nextMediaItem.id;
        this.nextVedioTitle =
          response.data.nextMediaItem && response.data.nextMediaItem.title;
        this.nextVedioImage =
          response.data.nextMediaItem && response.data.nextMediaItem.newwideimg;
        this.nxtVideoImg =
          response.data.nextMediaItem &&
          response.data.nextMediaItem.nextVideoImg;
        if (this.isLiveStreamingVideo) {
          this.getBroadCastingStatus(this.orderObj);
        }
        // added the validation to check whether the payment has beed done or not.
        if (response.data.currentMediaItem.mediaAccessType === 'FREE') {
          this.isOneTimePurchase = false;
        }
        setTimeout(() => {
          this.showafterLoad = true;
        }, 800);
        this.isReadMore = true;
        // this.inPlayerAds = response.data.currentMediaItem.vastTagDetails
        // ?
        // response.data.currentMediaItem.vastTagDetails.enableVastTag
        //   ?
        //   (response.data.currentMediaItem.vastTagDetails.adExchangeUrl && response.data.currentMediaItem.vastTagDetails.midRangeExchangeUrl)
        //     ?
        //     false
        //     :
        //     true
        //   :
        //   false
        // :
        // false;
        // this.inPlayerAds = response.data.currentMediaItem.vastTagDetails.enablePublisherUrl

        this.isVastTag = response.data.currentMediaItem.vastTagDetails ? response.data.currentMediaItem.vastTagDetails.enableVastTag : false;
        // this.vastTag = response.data.currentMediaItem.vastTagDetails ? response.data.currentMediaItem.vastTagDetails.adExchangeUrl : '';
        this.vastTag =
          response.data.currentMediaItem.vastTagDetails ?
            response.data.currentMediaItem.vastTagDetails.enablePublisherUrl ?
              response.data.currentMediaItem.vastTagDetails.publisherUrl :
              response.data.currentMediaItem.vastTagDetails.adExchangeUrl :
            '';

        this.midRollVastTagURL = response.data.currentMediaItem.vastTagDetails ? response.data.currentMediaItem.vastTagDetails.midRangeExchangeUrl : '';
        this.pauseTime = 0;

        let itemTypeValue = response.data.currentMediaItem.itemType ? response.data.currentMediaItem.itemType : '';
        if(this.vastTag !== '' && this.isVastTag && response.data.currentMediaItem.vastTagDetails.enablePublisherUrl && !(this.currentVedioIsPaid || this.isOneTimePurchase || this.loginRequired || itemTypeValue === 'LIVE_STREAMING') || this.vedioIsNull){
          this.inPlayerAds = response.data.currentMediaItem.vastTagDetails.enablePublisherUrl 
        }else{
          this.inPlayerAds = false
        }
        if (this.vastTag !== '' && this.isVastTag && !this.inPlayerAds && !(this.currentVedioIsPaid || this.isOneTimePurchase || this.loginRequired || itemTypeValue === 'LIVE_STREAMING') || this.vedioIsNull) {
          this.vastParser.getAndParseVAST(this.vastTag)
            .then(res => {
              console.log("vast parsed", this.inPlayerAds)

              this.preRollData = res.ads[0];
              this.adsType = 'preroll';
              this.videoJsPlayerUrl1 = this.preRollData?.creatives[0]?.mediaFiles[0]?.fileURL;
              this.videoJsPlayerType1 = this.preRollData?.creatives[0]?.mediaFiles[0]?.mimeType;
              this.showAds = !this.inPlayerAds;
            })
            .catch(err => {
              console.log('err', err);
            });
        }
        if (this.midRollVastTagURL && this.midRollVastTagURL !== '' && this.isVastTag && !this.inPlayerAds && itemTypeValue !== 'LIVE_STREAMING') {
          this.vastParser.getAndParseVAST(this.midRollVastTagURL)
            .then(res => {
              this.midRollDTO = res.ads[0];
            })
            .catch(err => {
              console.log('err', err);
            });
        }
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
        });
      }
    );
  }

  getYouTubeVideoId(url: string) {
    const urlObj = new URL(url);
    const searchParams = new URLSearchParams(urlObj.search);
    return searchParams.get('v');
  }

  getRelatedMediaItemWithout(page: any, size: any, id: any) {
    this.homeService.getRelatedMediaItemWithout(page, size, id).subscribe(
      (response: any) => {
        this.mediadata = response.data.content;
        this.mediadata.forEach((el) => {
          if (el.wideArtwork) {
            var width = 480;
            var height = 270;
            let id = el.wideArtwork.document.id;
            el.wideArtwork.document['newimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
          if (el.videoDTO) {
            el.videoDTO['vedioTime'] = this.sharedService.timeConvert(
              el.videoDTO.duration
            );
          } else if (el.videoDuration) {
            el['vedioTime'] = this.sharedService.timeConvert(el.videoDuration);
          }
        });

        this.mediadata = this.mediadata.map((el) => ({
          mediaImg: el.wideArtwork ? el.wideArtwork.document.newimg : '',
          duration: el.videoDTO
            ? el.videoDTO.vedioTime
            : el.vedioTime
              ? el.vedioTime
              : '0:00',
          title: el.title,
          subTitle: el.subTitle,
          color: el.wideArtwork ? el.wideArtwork.document.imageColur : '',
          id: el.id,
        }));
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  getRelatedMediaItemWith(page: any, size: any, id: any) {
    this.homeService.getRelatedMediaItemWith(page, size, id).subscribe(
      (response: any) => {
        this.mediadata = response.data.content;
        this.mediadata.forEach((el) => {
          if (el.wideArtwork) {
            var width = 480;
            var height = 270;
            let id = el.wideArtwork.document.id;
            el.wideArtwork.document['newimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
          if (el.videoDTO) {
            el.videoDTO['vedioTime'] = this.sharedService.timeConvert(
              el.videoDTO.duration
            );
          } else if (el.videoDuration) {
            el['vedioTime'] = this.sharedService.timeConvert(el.videoDuration);
          }
        });

        this.mediadata = this.mediadata.map((el) => ({
          mediaImg: el.wideArtwork ? el.wideArtwork.document.newimg : '',
          duration: el.videoDTO
            ? el.videoDTO.vedioTime
            : el.vedioTime
              ? el.vedioTime
              : (0).toFixed(2),
          title: el.title,
          subTitle: el.subTitle,
          color: el.wideArtwork ? el.wideArtwork.document.imageColur : '',
          id: el.id,
        }));
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  nextVedio(data: number, type: string) {
    this.playType = type;
    this.showafterLoad = false;
    this.youtubeID = ''
    this.isYoutubeUrl = false
    this.showAds = false;
    if (data) {
      this.nextMediaItemId = data
      this.orderObj = data;
      this.router.navigate([`/video/${data}`]);
      // this.GetMediaItemWithoutLogin(data)
      // this.getRelatedMediaItemWithout(1, 4, data);
      // if (this.hlsDriver === this.hlsjsDriver2) {
      //   this.hlsDriver = this.hlsjsDriver1
      //   this.play()
      // }
      // else if (this.hlsDriver === this.hlsjsDriver1) {
      //   this.hlsDriver = this.hlsjsDriver2
      //   this.play()
      // }
      if (type === 'related') {
        if (this.isLoggedIn) {
          this.GetMediaItemWithLogin(data);
          this.getRelatedMediaItemWith(1, 4, data);
          // setTimeout(() => {
          //   getWindow().location.reload();
          // }, 500);
        } else {
          this.GetMediaItemWithoutLogin(data);
          this.getRelatedMediaItemWithout(1, 4, data);
          // setTimeout(() => {
          //   getWindow().location.reload();
          // }, 500);
        }
      } else if (type === 'next') {
        if (this.isLoggedIn) {
          this.nextVedioImage = '';
          this.GetMediaItemWithLogin(data);
          this.getRelatedMediaItemWith(1, 4, data);
          // setTimeout(() => {
          //   getWindow().location.reload();

          // }, 500);
        } else {
          this.nextVedioImage = '';
          this.GetMediaItemWithoutLogin(data);
          this.getRelatedMediaItemWithout(1, 4, data);
          // setTimeout(() => {
          //   getWindow().location.reload();
          // }, 500);
        }
        // this.updateMetaTags(data);
      }
    }
  }

  favButtonClick() {
    this.isFavourites
      ? this.removeFavourites(this.orderObj)
      : this.addToFavourites(this.orderObj);
    this.isFavourites = !this.isFavourites;
  }

  addToFavourites(id: any) {
    this.homeService.addToFavourites(id).subscribe(
      (response: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Added to favourites.',
        });
        this.sharedService.updateLoader(false);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this.sharedService.updateLoader(false);
      }
    );
  }

  removeFavourites(id: any) {
    this.homeService.removeFavourites(id).subscribe(
      (response: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Removed from favourites.',
        });
        this.sharedService.updateLoader(false);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this.sharedService.updateLoader(false);
      }
    );
  }

  getAccess() {
    if (this.isLoggedIn) {
      this.router.navigate(['/signup'], {
        queryParams: {
          vedioId: this.orderObj,
          id: this.PlanID,
          showOffers: true,
          clickFrom: 'program',
        },
      });
    } else {
      this.router.navigate(['/signup'], {
        queryParams: { vedioId: this.orderObj, showOffers: true },
      });
    }
  }

  buyItem() {
    if (this.isLoggedIn) {
      this.router.navigate(['/signup'], {
        queryParams: {
          vedioId: this.orderObj,
          id: this.PlanID,
          buyItem: true,
          ItemPrice: this.itemPrice,
          clickFrom: 'program',
        },
      });
    } else {
      this.router.navigate(['/signup'], {
        queryParams: {
          vedioId: this.orderObj,
          ItemPrice: this.itemPrice,
          buyItem: true,
        },
      });
    }
  }

  signInRequired() {
    this.router.navigate(['/signup'], {
      queryParams: {
        vedioId: this.orderObj,
        clickFrom: 'programsignInRequire',
        loginRequired: true
      },
    });
  }

  signInOnMedia() {
    this.router.navigate(['/signin'], {
      queryParams: { vedioId: this.orderObj },
    });
  }

  showText() {
    this.isReadMore = !this.isReadMore;
  }

  updateTitle(title: string) {
    // this.title.setTitle(title);
  }
  updateMetaTags(id: number) {
    this.homeService
      .getMediaDataByIdWithout(id)
      .toPromise()
      .then((response: any) => {
        let ogImage =
          environment.imageService +
          response.data.currentMediaItem.wideArtworkId +
          '?height=270&width=480';
        this._Title.setTitle(response.data.currentMediaItem.title);
        this._Meta.updateTag({
          property: 'og:title',
          content: response.data.currentMediaItem.title,
        });
        this._Meta.updateTag({ property: 'og:image', content: ogImage });
        this._Meta.updateTag({ property: 'og:url', content: ogImage });
      });
  }

  updateAutoPlayStatus(data: any) {
    if (this.isLoggedIn) {
      this.homeService.updateAutoPlayStatus(data).subscribe(
        (response: any) => {
          this.autoPlay = data;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Status changed.',
          });
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error while changing status.',
          });
        }
      );
    } else {
      this._sessionService.setSession({ autoplay: data });
      this.autoPlay = data;
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Status changed.',
      });
    }
    this.sharedService.updateLoader(false);
  }
  getBroadCastingStatus(id: number): void {
    this.homeService
      .getMediaStatus(id)
      .then((data) => {
        if (data.data.status !== 'created') {
          this.notLiveMsg = false;
        }
      })
      .catch((err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error while changing status.',
        });
        this.sharedService.updateLoader(false);
      });
  }

  savePlayer(player: YT.Player) {
    this.player = player;
    this.player.loadVideoById(this.youtubeID)
    console.log('player instance', player);
  }
  onStateChange(event: { data: any; }) {
    console.log('player state', event.data);
    let duration = this.player.getDuration()
    let currentDuration = this.player.getCurrentTime()
    if (duration === currentDuration && duration > 0 && currentDuration > 0) {
      if (this.autoPlay) {
        this.player.stopVideo()
        this.youtubeID = ''
        this.isYoutubeUrl = false
        setTimeout(() => {
          this.nextVedio(this.nextVedioId, 'next')
        }, 100);
      }
    }
    console.log(duration, 'duration', currentDuration, 'currentDuration')
  }

  getLogoDimention() {
    this.homeService.dimensions$.subscribe((dimensions) => {
      this.logoHeight = dimensions.height
    });
  }

  getLocalStorageVastDTO() {
    // let vastStorage = localStorage.getItem('vastDTO')
    // if(vastStorage){
    //   this.vastDTO = vastStorage ? JSON.parse(vastStorage): '';
    //   this.trackingEvents =  this.vastDTO.ads[0].creatives[0].trackingEvents;
    //   this.preRollDTO = this.vastDTO;
    //   }
  }

  getTrackingEventAPI(eventUrl: any) {
    fetch(eventUrl)
      .then(response => response)
      .catch(err => err.message);
  }
}
